#main {
	padding-top: 150px;

	h1.page-title {
		@extend h2;
	}

	#answer {
		padding-top: rem-calc(32);
		#answered {
			h2 {
				font-weight: bold;
				@extend h1;
				line-height: 1;
				margin-bottom: rem-calc(16);
			}
		}
	}
}